import React, { useContext, useState } from "react";
import ModalDialog from "@cx/ui/ModalDialog";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import {
  HANGTAG,
  CHANGE_ADVISOR,
  CHANGE_PROMISED_TIME,
  CHANGE_MILEAGE_IN,
  CHANGE_MILEAGE_OUT,
  CHANGE_TRANSPORTATION
} from "../constants/adjustment.constant";
import isEmpty from "lodash/isEmpty";
import ErrorBoundary from "./error-boundary.component";
import HangTag from "./hang-tag/hang-tag.component";
import Advisor from "./change-advisor/change-advisor.component";
import { useNewQuoteContext, Actions } from "../../../state/NewQuoteContext";
import { AppContext } from "../../../state/app-context";
import csrService from "../../quote-summary/services/csr.service";
import PromisedTime from "./change-promised-time/change-promised-time.component";
import moment from "moment";
import ChangeMileageOut from "./change-mileageOut/change-mileage-Out.component";
import ChangeMileageIn from "./change-mileageIn/change-mileage-In.component";
import Transportation from "./change-transportation/change-transportation.component";

const TopLevelMenuModal = props => {
  const { state, dispatch } = useNewQuoteContext();
  const appContext = useContext(AppContext);

  const { currentModalType } = props;
  const { quoteSummary, advisors } = state;
  const quoteSummaryWithAdvisors = { quoteSummary, advisors };
  const [showModal, setShowModal] = useState(
    currentModalType === null ? false : true
  );
  const [payload, setPayload] = useState(null);
  const getModalHeader = () => {
    let header = {};
    switch (currentModalType) {
      case HANGTAG:
        header = {
          header: `Change hang tag`,
          width: "quarter-width"
        };
        break;
      case CHANGE_ADVISOR:
        header = {
          header: `Change service advisor`,
          width: "quarter-width"
        };
        break;
      case CHANGE_PROMISED_TIME:
        header = {
          header: `Change promised time`,
          width: "quarter-width"
        };
        break;
      case CHANGE_MILEAGE_IN:
        header = {
          header: `Change mileage in`,
          width: "quarter-width"
        };
        break;
      case CHANGE_MILEAGE_OUT:
        header = {
          header: `Change mileage out`,
          width: "quarter-width"
        };
        break;
      case CHANGE_TRANSPORTATION:
        header = {
          header: `Change transportation`,
          width: "half-width"
        };
        break;
      default:
        header = {
          header: "",
          width: ""
        };
    }
    return header;
  };

  const handleOnUpdate = payload => {
    setPayload(payload);
  };

  const handlePromisedDateTime = payload => {
    if (payload.date && payload.time) {
      const pickUpDateTime = moment(
        `${payload.date} ${payload.time}`,
        "MM/DD/YYYY hh:mm A"
      ).format("YYYY-MM-DDTHH:mm:ssZ");
      setPayload({ pickUpDateTime });
    }
  };

  const renderContent = () => {
    let content = null;
    //* Hangtag content
    if (currentModalType === HANGTAG) {
      content = (
        <HangTag
          quoteSummary={quoteSummary}
          onUpdate={handleOnUpdate}
          onEnterKeyPressOnUpdate={handleEnterKeyPress}
        />
      );
    }
    if (currentModalType === CHANGE_ADVISOR) {
      content = (
        <Advisor
          quote={quoteSummaryWithAdvisors}
          onUpdate={handleOnUpdate}
          onEnterKeyPressOnUpdate={handleEnterKeyPress}
        />
      );
    }
    if (currentModalType === CHANGE_PROMISED_TIME) {
      content = (
        <PromisedTime
          quoteSummary={quoteSummary}
          onUpdate={handlePromisedDateTime}
        />
      );
    }
    if (currentModalType === CHANGE_MILEAGE_IN) {
      content = (
        <ChangeMileageIn
          quoteSummary={quoteSummary}
          onUpdate={handleOnUpdate}
          onEnterKeyPressOnUpdate={handleEnterKeyPress}
        />
      );
    }
    if (currentModalType === CHANGE_MILEAGE_OUT) {
      content = (
        <ChangeMileageOut
          quoteSummary={quoteSummary}
          onUpdate={handleOnUpdate}
          onEnterKeyPressOnUpdate={handleEnterKeyPress}
        />
      );
    }
    if (currentModalType === CHANGE_TRANSPORTATION) {
      content = (
        <Transportation
          transportationList={state?.transportationList}
          selectedTransport={state?.quoteSummary?.transportation?.start}
          onUpdate={handleOnUpdate}
        />
      );
    }
    return content;
  };

  function handleEnterKeyPress() {
    if (currentModalType === HANGTAG) {
      saveAction();
    } else if (
      currentModalType === CHANGE_ADVISOR ||
      currentModalType === CHANGE_MILEAGE_IN ||
      currentModalType === CHANGE_MILEAGE_OUT
    ) {
      saveAction();
    }
    if (currentModalType === CHANGE_PROMISED_TIME) {
      if (payload.date && payload.time) saveAction();
    }
  }

  // ok/save handler
  const saveAction = () => {
    setShowModal(false);
    if (payload !== null) {
      patchCSRTopLevelMenu(payload);
    }
    props.callbackCloseAction();
  };

  // @csr-logic
  const patchCSRTopLevelMenu = async csrPayload => {
    showPageLoading(true);
    try {
      const response = await csrService.patchCsrTopLevelMenuChanges({
        appContext,
        quoteSummary,
        csrPayload
      });
      if (!isEmpty(response)) {
        const { confirmationId } = response;
        if (!isEmpty(confirmationId)) {
          if (currentModalType === CHANGE_TRANSPORTATION) {
            dispatch({
              type: Actions.SET_TRANSPORTATION,
              payload: response?.transportation || null
            });
          } else if (currentModalType === CHANGE_PROMISED_TIME) {
            dispatch({
              type: Actions.SET_PROMISED_TIME,
              payload: response?.pickUpDateTime || null
            });
          } else
            dispatch({
              type: Actions.UPDATE_QUOTE,
              payload: response
            });
        }
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      showPageLoading(false);
    }
  };

  const showPageLoading = showPageMask => {
    dispatch({
      type: Actions.SET_PAGE_MASK,
      payload: showPageMask
    });
  };

  // close icon click handler
  const hideModalHandler = () => {
    setShowModal(false);
    props.callbackCloseAction();
  };

  // cancel handler
  const cancelAction = () => {
    setShowModal(false);
    props.callbackCloseAction();
  };

  const modalTypeClassName = currentModalType.toLowerCase().replace(/\s/g, "-");

  const checkButtonEnabled = () => {
    let buttonDisabled = false;
    switch (currentModalType) {
      // TODO:  Disabling the validation until clarification in future
      // case CHANGE_PROMISED_TIME:
      //   buttonDisabled =
      //     moment(payload?.pickUpDateTime).format("MM/DD/YYYY") <
      //     moment(new Date()).format("MM/DD/YYYY")
      //       ? true
      //       : false;
      //   break;
      case CHANGE_MILEAGE_IN:
        buttonDisabled =
          isEmpty(payload?.mileageIn) ||
          payload?.mileageIn == 0 ||
          (!!quoteSummary?.mileageOut &&
            payload?.mileageIn > quoteSummary?.mileageOut);
        break;
      case CHANGE_MILEAGE_OUT:
        buttonDisabled = payload?.mileageOut < quoteSummary?.mileageIn;
        break;
      default:
        buttonDisabled = false;
    }
    return buttonDisabled;
  };
  return (
    <ModalDialog
      htmlId="RODetailsHeaderModalDialog"
      backdrop="static"
      className={`sample-modal-dialog  ${
        getModalHeader().width
      } modal-type-${modalTypeClassName}`}
      show={showModal}
      header={<h4 className="modal-title">{getModalHeader().header}</h4>}
      footer={
        <div>
          <Button
            htmlId="cancelbtnModal"
            onClick={cancelAction}
            buttonStyle="secondary"
            tabIndex="2"
          >
            Cancel
          </Button>
          <Button
            htmlId="okbtnModal"
            onClick={saveAction}
            tabIndex="1"
            disabled={checkButtonEnabled()}
          >
            Update
          </Button>
        </div>
      }
      onHide={hideModalHandler}
    >
      <ErrorBoundary>{renderContent()}</ErrorBoundary>
    </ModalDialog>
  );
};

export default TopLevelMenuModal;

TopLevelMenuModal.propTypes = {
  callbackCloseAction: PropTypes.func,
  currentModalType: PropTypes.string
};

TopLevelMenuModal.defaultProps = {
  // events
  callbackCloseAction: () => {},
  // data
  currentModalType: null
};
