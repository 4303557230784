/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import CustomLoadingOverlay from "./reusable/loadingmask/loading-overlay.component";
import ConfirmPopup from "./reusable/confirm-popup.component";
import useComponentDidMount from "../hooks/useComponentDidMount";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import Tooltip from "@cx/ui/Tooltip";
import IconDelete from "@cx/ui/Icons/IconDelete";
import Badge from "@cx/ui/Badge";
import {
  transformValueIfUndefined,
  calculateTotalPrice,
  isItCorePart,
  isPartInstock,
  getPartAvailabilityLabel,
  isPartFieldEditable,
  hasQuantityAvailable,
  generatePartId
} from "../utils/helper.util";
import { refinePriceSource, getPartDescription } from "../utils/parts.util";
import { usePartsLookupContext, Actions } from "../state/parts-lookup.context";
import NumericEditor from "./editors/numeric.editor";
import TextEditor from "./editors/text.editor";
import { applications, priceSourceLabels } from "../constants/parts.constants";
import PartActionsCellRenderer, {
  partActionsGlobals
} from "./renderers/part-actions-cell-renderer.component";
import { checkIfIsCSR } from "../../api/app.util";
import defaultTo from "lodash/defaultTo";
import has from "lodash/has";
import { toEmptyStringIfUndefined } from "../utils/string";
import PurchaseOrderDetailsSectionComponent from "./purchase-order-details-section.component";
const SelectedPartsGrid = props => {
  const {
    parts,
    enableEdit,
    service,
    showActions,
    actionType,
    isPartsModal,
    getPurchaseOrderDetails,
    isEmergencyPartsFlagOn,
    isCreateSpecialOrderFlagOn
  } = props; // parts as props coming from edit container

  const [maxRows] = useState(props.maxRows || 10);
  const [totalPrice, setTotalPrice] = useState(0);
  const [gridOptions, setGridOptions] = useState({});
  const [gridColumnDefs, setGridColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [domLayout, setDomLayout] = useState(null);
  const { state, dispatch } = usePartsLookupContext();
  const [serviceParts, setServiceParts] = useState([]);
  const [unitCostError, setUnitCostError] = useState(false);
  const [unitCostErrorMessage, setUnitCostErrorMessage] = useState("");
  const [expandedRowId, setExpandedRowId] = useState(null);
  const { appType, isPartsView, debugMode, userPermissions } = state;
  const isCSR = checkIfIsCSR(appType);

  const isSpecialOrderFlagEnabled =
    state?.dealerProperties?.ENG_USE_SPECIAL_ORDER_PARTS === "Y" ? true : false;
  const gridRef = useRef();
  useComponentDidMount(() => {
    const gridOptions = gridConfig();
    setGridOptions(gridOptions);
  });
  useEffect(() => {
    if (appType) {
      setGridColumnDefs(getColumnList());
    }
  }, [appType]);

  // listee to parts property change
  useEffect(() => {
    //* setting unitCost as costPrice if costPrice is not override i.e unitCostOverride =false
    parts?.forEach(item => {
      Object.assign(item, { poDetailsDataIcon: "" });
    });
    if (isPartsView) {
      parts.map(part => {
        part.costPrice =
          part?.costPrice === undefined || part?.costPrice === ""
            ? null
            : part?.costPrice;
        part.unitCost = part?.unitCost === undefined ? null : part?.unitCost;
        part.unitCostOverride =
          part?.costPrice === undefined && part?.unitCostOverride === undefined
            ? false
            : part?.unitCostOverride || false;
      });

      parts?.forEach(item => {
        if (
          actionType === "MODIFY_PARTS" &&
          item.quantity > item.quantityAvailable &&
          item.quantityAvailable !== 0
        ) {
          const outOfStockQuantity = item.quantity - item.quantityAvailable;
          item.quantity = item.quantityAvailable;
          addOutOfStockSplitLine(item, outOfStockQuantity);
        }
      });
      props.getParts({ parts }, true);
    }

    // update state
    setServiceParts(parts);
  }, [parts]);

  useEffect(() => {
    calculatePartsTotal(serviceParts);
    findAllPartsHasUnitCostHigherThanUnitPrice(serviceParts);
    setRowData(serviceParts);
  }, [serviceParts]);

  useEffect(() => {
    gridRef.current.api.refreshCells();
  }, [rowData]);

  const findAllPartsHasUnitCostHigherThanUnitPrice = () => {
    const priceHighError = serviceParts?.some(
      part => part?.unitPrice >= 0 && part?.unitCost > part?.unitPrice
    );
    const costMissingError = serviceParts?.some(
      part => part.unitCost === "" || part.unitCost === null
    );
    let errorMessage = "";
    if (costMissingError && !priceHighError) {
      errorMessage = "Cost is missing";
    } else if (!costMissingError && priceHighError) {
      errorMessage = "Warning - Price is below cost";
    } else if (costMissingError && priceHighError) {
      errorMessage = "Cost is missing. ";
    }
    setUnitCostError(priceHighError || costMissingError);
    setUnitCostErrorMessage(errorMessage);
  };

  const calculatePartsTotal = parts => {
    const totalPartsPrice = calculatePriceForAllParts(
      parts,
      "Trigger PartsPriceTotal"
    );
    setTotalPrice(totalPartsPrice);
  };

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const isCellEditable = () => {
    return enableEdit;
  };

  // This method returns calculated parts price or partsPriceOverride based on price override algorithm
  const calculatePriceForAllParts = (partsList, str) => {
    let totalPartsPrice = 0;
    const calculatedTotalPartsPrice = calculateTotalPrice(partsList);
    if (service.totalPriceOverridden) {
      totalPartsPrice = calculatedTotalPartsPrice;
    } else if (
      service.partsPriceOverridden ||
      service.totalPartsPriceOverride
    ) {
      // edit service context will update service with overirde, final fields
      totalPartsPrice = service.totalPartsPriceOverride;
    } else {
      totalPartsPrice = calculatedTotalPartsPrice;
    }
    console.log(
      "PARTS MODAL - Calculate PartsPrice",
      str,
      totalPartsPrice,
      calculatedTotalPartsPrice,
      service.finalPartsPrice,
      service,
      partsList
    );
    return totalPartsPrice;
  };

  const gridConfig = () => {
    return {
      // gridId: prevent unnecessary grid rendering when there are multiiple grids in a page
      gridId: defaultTo(service.quoteServiceId, "xyz").toString(),
      enableEdit,
      // ag-grid props
      rowSelection: "single", // allows single row selection
      multiSortKey: "ctrl",
      defaultColDef: {
        flex: 1,
        floatingFilter: false, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        suppressMenu: true,
        sortingOrder: ["asc", "desc", null],
        minWidth: 50,
        maxWidth: 500,
        autoHeight: true,
        // cellStyle: {
        //   padding: "8px",
        //   color: "black",
        //   textAlign: "left"
        // },
        menuTabs: ["filterMenuTab"],
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        headerClass: "ag-text-header"
      },
      rowGroup: false,
      columnTypes: {
        numberColumn: {
          width: 160,
          filter: "agNumberColumnFilter"
        },
        editableColumn: {
          editable: params => {
            return isCellEditable(params);
          }
        },
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        },
        nonEditableColumn: {
          editable: false
        }
      },
      isRowSelectable() {
        return true; // to see checkbox
      },
      // Note: In order to prevent racing issues, not setting frameworkComponents here anymore. Passing them as an object contant below.
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      onColumnMoved: refreshGrid,
      onColumnPinned: refreshGrid,
      hiddenByDefault: true,
      sideBar: false,
      domLayout: "autoHeight",
      adjustGridColumns
    };
  };
  const checkValidUnitCost = params => {
    const result =
      (showActions || isPartsView) &&
      (params?.data?.unitCostOverride
        ? params?.value === "" || params?.value === null
        : params?.data?.unitCost === null || params?.data?.unitCost === "");
    return result;
  };

  const getColumnList = () => {
    if (showActions) {
      return getPartsViewColumnList();
    }
    return [
      {
        headerName: "",
        field: "poDetailsDataIcon",
        cellRenderer: "agGroupCellRenderer",
        maxWidth: 100,
        hide:
          !isCSR ||
          (isCSR &&
            (!isEmergencyPartsFlagOn ||
              actionType === "MODIFY_PARTS" ||
              actionType === "EDIT_SUMMARY" ||
              actionType === "EDIT_NEW"))
      },
      {
        field: "remove",
        headerName: !isCSR ? "Remove" : "",
        editable: false,
        cellClass: "xmm-wrap-text",
        cellRendererFramework: removePartCellRenderer,
        enableRowGroup: false,
        maxWidth: isCSR ? 50 : 80,
        minWidth: isCSR ? 50 : 70,
        filter: false,
        suppressSizeToFit: false,
        suppressColumnsToolPanel: true,
        hide: isPartsView || (isCSR && !userPermissions.canRemoveParts)
      },
      {
        field: "approver",
        headerName: "Status",
        editable: false,
        cellRendererFramework: partStatusCellRenderer,
        hide: !isCSR,
        minWidth: 100,
        maxWidth: 150
      },
      {
        field: "quantity",
        headerName: "Qty",
        headerClass: "ag-text-header",
        // editable: enableEdit,
        editable: params => {
          if (isCSR) {
            return isPartFieldEditable(params.data, "quantity");
          }
          return enableEdit;
        },
        cellClass: ["d-block-cell", "editable-cell"],
        cellStyle: {
          color: "black",
          textAlign: "left"
        },
        cellEditor: "numericEditor",
        cellEditorParams() {
          const allowDecimals = false;
          const minValue = 1;
          return { allowDecimals, minValue };
        },
        type: "numberColumn",
        cellRendererFramework: quantityCellRenderer,
        suppressSizeToFit: false,
        minWidth: 80,
        maxWidth: 100
      },
      {
        field: "partName",
        tooltipField: "partName",
        headerName: "Description",
        headerClass: "ag-text-header",
        editable: enableEdit,
        cellEditor: "textEditor",
        cellEditorParams: {
          maxLength: 200
        },
        cellRendererFramework: descriptionCellRenderer,
        sortable: true,
        flex: 2,
        minWidth: 200,
        maxWidth: 680,
        suppressSizeToFit: false,
        cellClass: ["xmm-wrap-text", "editable-cell"]
      },
      {
        field: "oemPartNumber",
        tooltipField: "oemPartNumber",
        headerName: "Part number",
        headerClass: "ag-text-header",
        cellRendererFramework: partNumberCellRenderer,
        minWidth: 100,
        maxWidth: 150,
        suppressSizeToFit: false,
        cellClass: "xmm-wrap-text"
      },
      {
        field: "availability",
        headerName: "Availability",
        headerClass: "ag-text-header",
        cellRendererFramework: availabilityCellRenderer,
        minWidth: 100,
        maxWidth: 120,
        hide: !isCSR,
        suppressSizeToFit: false,
        cellClass: "xmm-wrap-text"
      },
      {
        field: "purchaseType",
        headerName: "Type",
        headerClass: "ag-text-header ag-mandatory-column",
        cellRenderer: purchaseTypeRenderer,
        refData: purchaseTypeMap,
        editable: false,
        maxWidth: 130,
        minWidth: 130,
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true
      },
      {
        field: "location",
        headerName: "Location",
        headerClass: "ag-text-header",
        cellRendererFramework: locationRenderer,
        minWidth: 100,
        maxWidth: 120,
        suppressSizeToFit: false,
        cellClass: "xmm-wrap-text"
      },
      {
        field: "type",
        headerName: "Type",
        headerClass: "ag-text-header ag-mandatory-column",
        cellRendererFramework: typeRenderer,
        minWidth: 100,
        maxWidth: 120,
        hide: !debugMode,
        suppressSizeToFit: false,
        cellClass: "xmm-wrap-text"
      },
      {
        field: "location",
        headerName: "Location",
        headerClass: "ag-text-header",
        cellRendererFramework: locationRenderer,
        minWidth: 100,
        maxWidth: 120,
        hide: !debugMode,
        suppressSizeToFit: false,
        cellClass: "xmm-wrap-text"
      },
      {
        field: "cost",
        headerName: "Cost",
        headerClass: "ag-text-header",
        cellRendererFramework: costRenderer,
        minWidth: 70,
        maxWidth: 80,
        hide: !isCSR || (isCSR && !userPermissions.canViewPrices),
        suppressSizeToFit: false,
        cellStyle: {
          "text-align": "right"
        },
        cellClass: "xmm-grid-price"
      },
      {
        field: "unitPrice",
        headerName: "Unit",
        headerClass: "ag-numeric-header",
        cellClass: ["d-block-cell", "editable-cell"],
        editable: params => {
          if (isCSR) {
            if (isPartsView) {
              // enable for parts person
              return (
                enableEdit && isPartFieldEditable(params.data, "unitPrice")
              );
            }
            // not editable for advisor
            return false;
          }
          return enableEdit;
        },
        type: "numberColumn",
        cellEditor: "numericEditor",
        cellEditorParams() {
          const allowDecimals = true;
          const decimalPlaces = 2;
          return { allowDecimals, decimalPlaces };
        },
        cellRendererFramework: priceCellRenderer,
        suppressSizeToFit: false,
        minWidth: 120,
        maxWidth: 150,
        hide: isCSR && !userPermissions.canViewPrices
      },
      {
        field: "partsPrice",
        headerName: "Total",
        editable: false,
        type: "numberColumn",
        cellClass: "xmm-grid-price",
        cellStyle: {
          "text-align": "right"
        },
        headerClass: "ag-numeric-header",
        cellRendererFramework: totalPriceCellRenderer,
        hide: !isCSR || (isCSR && !userPermissions.canViewPrices),
        minWidth: 80,
        maxWidth: 100
      },
      {
        field: "quoteServicePartId",
        headerName: "Quote part ID",
        headerClass: "ag-text-header",
        flex: 1,
        minWidth: 80,
        maxWidth: 80,
        hide: !props.showPartId,
        suppressSizeToFit: false,
        suppressColumnsToolPanel: true
      },
      {
        field: "partId",
        headerName: "PartId",
        headerClass: "ag-text-header",
        flex: 1,
        minWidth: 80,
        maxWidth: 80,
        hide: !props.showPartId,
        suppressSizeToFit: false,
        suppressColumnsToolPanel: true
      },
      {
        field: "rowId",
        headerName: "Row ID",
        headerClass: "ag-text-header",
        flex: 1,
        minWidth: 80,
        maxWidth: 80,
        hide: !props.showPartId,
        suppressSizeToFit: false,
        suppressColumnsToolPanel: true
      }
    ];
  };

  const purchaseTypeMap = isSpecialOrderFlagEnabled
    ? {
        "Special Order": "Special Order",
        "EP/Drop": "EP/Drop Ship",
        stock: "Stock"
      }
    : {
        "EP/Drop": "EP/Drop Ship",
        stock: "Stock"
      };
  const isCostReadOnly = data => {
    const inStock = isPartInstock(data?.quantity, data?.quantityAvailable);
    return inStock || !data?.purchaseType || data?.purchaseType !== "EP/Drop";
  };

  const getPartsViewColumnList = () => {
    return [
      {
        headerName: "",
        field: "poDetailsDataIcon",
        cellRenderer: "agGroupCellRenderer",
        maxWidth: 100,
        hide: !isEmergencyPartsFlagOn || actionType === "MODIFY_PARTS"
      },
      {
        field: "approver",
        headerName: "Status",
        editable: false,
        cellRendererFramework: partStatusCellRenderer,
        minWidth: 100,
        maxWidth: 150
      },
      {
        field: "quantity",
        headerName: "Qty",
        headerClass: "ag-text-header",
        cellClass: ["d-block-cell", "editable-cell"],
        cellStyle: {
          color: "black"
        },
        cellEditor: "numericEditor",
        editable: params => {
          return (
            actionType === "MODIFY_PARTS" &&
            isPartFieldEditable(params.data, "quantity") &&
            (params.data.partsApprover === null ||
              params.data.partsApprover === undefined)
          );
        },
        cellEditorParams() {
          const allowDecimals = false;
          const minValue = 1;
          return { allowDecimals, minValue };
        },
        type: "numberColumn",
        cellRendererFramework: quantityCellRenderer,
        suppressSizeToFit: false,
        minWidth: 80,
        maxWidth: 100
      },
      {
        field: "partName",
        tooltipField: "partName",
        headerName: "Description",
        headerClass: "ag-text-header",
        cellEditor: "textEditor",
        cellRendererFramework: descriptionCellRenderer,
        sortable: true,
        minWidth: 200,
        maxWidth: 680,
        suppressSizeToFit: false,
        editable: params =>
          actionType === "MODIFY_PARTS" && !params?.data?.approver,
        cellClass: ["xmm-wrap-text", "editable-cell"]
      },
      {
        field: "oemPartNumber",
        tooltipField: "oemPartNumber",
        headerName: "Part number",
        headerClass: "ag-text-header",
        cellRendererFramework: partNumberCellRenderer,
        cellStyle: { textOverflow: "ellipsis" },
        minWidth: 110,
        maxWidth: 150,
        suppressSizeToFit: false
        // cellClass: "xmm-wrap-text"
      },
      {
        field: "availability",
        headerName: "Availability",
        headerClass: "ag-text-header",
        cellRendererFramework: availabilityCellRenderer,
        minWidth: 100,
        maxWidth: 120,
        suppressSizeToFit: false,
        cellClass: "xmm-wrap-text"
      },
      {
        field: "purchaseType",
        headerName: isSpecialOrderFlagEnabled ? "Fullfillment method" : "Type",
        headerClass: "ag-text-header ag-mandatory-column",
        cellClass: params => {
          if (params.data.partType === "fluid" || params.data.isCorePart) {
            return "";
          }
          if (isSpecialOrderFlagEnabled) {
            const originalPart = service?.parts?.find(
              part => part?.extPartId === params?.data?.rowId
            );
            if (originalPart?.purchaseType === "EP/Drop") return "";
          }
          return actionType === "MODIFY_PARTS" &&
            !isPartInstock(params.data.quantity, params.data.quantityAvailable)
            ? "editable-triangle"
            : "";
        },
        cellRenderer: purchaseTypeRenderer,
        cellEditorSelector() {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: Object.keys(purchaseTypeMap)
            }
          };
        },
        refData: purchaseTypeMap,
        editable: params => {
          if (params.data.partType === "fluid" || params.data.isCorePart) {
            return false;
          }
          if (isSpecialOrderFlagEnabled) {
            const originalPart = service?.parts?.find(
              part => part?.extPartId === params?.data?.rowId
            );
            if (originalPart?.purchaseType === "EP/Drop") return false;
          }
          return (
            actionType === "MODIFY_PARTS" &&
            !isPartInstock(params.data.quantity, params.data.quantityAvailable)
          );
        },
        maxWidth: 130,
        minWidth: 130,
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true
      },
      {
        field: "location",
        headerName: "Location",
        headerClass: "ag-text-header",
        cellRendererFramework: locationRenderer,
        minWidth: 100,
        maxWidth: 120,
        suppressSizeToFit: false,
        cellClass: "xmm-wrap-text"
      },
      {
        field: "unitCost",
        headerName: "Cost",
        headerClass: "ag-text-header",
        cellClass: "xmm-grid-price",
        cellClassRules: {
          "unit-cost-cell-error-red xmm-grid-price": params =>
            checkValidUnitCost(params),
          "xmm-grid-price": params => !checkValidUnitCost(params)
        },
        cellRendererFramework: costRenderer,
        cellStyle: {
          "text-align": "right"
        },
        minWidth: 70,
        maxWidth: 80,
        suppressSizeToFit: false,
        editable: params => {
          return (
            actionType === "MODIFY_PARTS" &&
            !params?.data?.approver &&
            !isCostReadOnly(params?.data)
          );
        },
        cellEditor: "numericEditor",
        cellEditorParams() {
          const allowDecimals = true;
          const decimalPlaces = 2;
          return { allowDecimals, decimalPlaces };
        }
      },
      {
        field: "unitPrice",
        headerName: "Unit",
        headerClass: "ag-numeric-header",
        cellClass: ["d-block-cell", "editable-cell"],
        // cellStyle: { textOverflow: "ellipsis", padding: "8px" },
        type: "numberColumn",
        cellEditor: "numericEditor",
        editable: params => {
          return (
            actionType === "MODIFY_PARTS" &&
            isPartFieldEditable(params.data, "unitPrice") &&
            !isCostReadOnly(params?.data)
          );
        },
        cellEditorParams() {
          const allowDecimals = true;
          const decimalPlaces = 2;
          return { allowDecimals, decimalPlaces };
        },
        cellRendererFramework: priceCellRenderer,
        suppressSizeToFit: false,
        minWidth: 130,
        maxWidth: 150
      },
      {
        field: "partsPrice",
        headerName: "Total",
        editable: false,
        type: "numberColumn",
        cellClass: "xmm-grid-price",
        cellStyle: {
          "text-align": "right"
        },
        headerClass: "ag-numeric-header",
        cellRendererFramework: totalPriceCellRenderer,
        minWidth: 60,
        maxWidth: 90
      },
      {
        field: "partActions",
        headerName: "",
        headerClass: "ag-text-header",
        pinned: "right",
        cellStyle: {
          padding: "0px"
        },
        cellRendererFramework: PartActionsCellRenderer,
        cellRendererParams: {
          quoteSummary: props.quoteSummary,
          onPerformPartAction: props.onPerformPartAction,
          showRemovePartModal: props.showRemovePartModal,
          showCorePartReturnModal: props.showCorePartReturnModal,
          isCreateSpecialOrderFlagOn: props.isCreateSpecialOrderFlagOn,
          gridRef,
          checkRemoveButtonDisabled
        },
        maxWidth: 35,
        minWidth: 35,
        width: 35,
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true,
        hide: !props.showActions || actionType === "MODIFY_PARTS",
        sortable: false
      }
    ];
  };

  // This event fired after a cell has been changed with default editing
  const onCellValueChanged = useCallback(
    params => {
      const field = params.colDef.field;
      if (field === "unitPrice") {
        if (params.oldValue !== Number(params.newValue)) {
          // @note: UI field "partPriceSource" used to hold catalog API value/ or modified unitPrice cell value
          params.data.partPriceSource = priceSourceLabels.MANUAL;
          // This dispatch used to include partId in payload. Now, it includes unique rowId coming from API.
          dispatch({
            type: Actions.SET_SERVICE_PART_PRICE,
            payload: {
              value: params.value,
              rowId: params.data.rowId
            }
          });
          calculatePartsTotal(parts);
        }
      } else if (field === "quantity") {
        if (params.oldValue !== params.newValue) {
          if (has(params, "value")) {
            // console.log("celledit qty", params.data, params.value);
            // This dispatch used to include partId in payload. Now, it includes unique rowId coming from API.
            dispatch({
              type: Actions.SET_SERVICE_PART_QTY,
              payload: {
                qty: params.value,
                rowId: params.data.rowId
              }
            });
          }
          calculatePartsTotal(parts);
        }
      } else if (field === "partName") {
        if (params.oldValue !== params.newValue) {
          // console.log("celledit partName", params, params.value);
          // This dispatch used to include partId in payload. Now, it includes unique rowId coming from API.
          dispatch({
            type: Actions.SET_SERVICE_PART_NAME,
            payload: {
              name: params.value,
              rowId: params.data.rowId
            }
          });
        }
      } else if (field === "unitCost") {
        if (+params.oldValue !== +params.newValue) {
          if (params?.newValue !== "" + params?.data?.costPrice) {
            const { isPartsView } = state;
            const isPartInStock = isPartInstock(
              params?.data?.quantity,
              params?.data?.quantityAvailable
            );
            isPartsView &&
              !isPartInStock &&
              updateEmergencyPartsPricing(params);
            params.data.unitCostOverride = true;
          } else params.data.unitCostOverride = false;
          if (params?.newValue === "" && params?.data?.costPrice === null)
            params.data.unitCostOverride = false;
          dispatch({
            type: Actions.SET_SERVICE_PART_UNIT_COST_PRICE,
            payload: {
              unitCost: params.value !== "" ? parseFloat(params.value) : null,
              rowId: params.data.rowId
            }
          });
        }
      } else if (field === "purchaseType") {
        if (params.oldValue !== params.newValue) {
          dispatch({
            type: Actions.SET_SERVICE_PART_TYPE,
            payload: {
              purchaseType: params.data.purchaseType,
              rowId: params.data.rowId
            }
          });
        }
      }
    },
    [parts]
  );
  const addOutOfStockSplitLine = (data, outOfStockQuantity) => {
    const keyId = generatePartId();
    const newLine = {
      ...data,
      rowId: keyId,
      partId: keyId.toString(),
      extPartId: keyId,
      quantityAvailable: 0,
      quantity: outOfStockQuantity,
      purchaseType: "",
      quoteServicePartId: null,
      roPartNum: null
    };
    const mergedList = parts.concat(newLine);
    dispatch({
      type: Actions.SET_API_CALL_PENDING,
      payload: false
    });
    dispatch({
      type: Actions.SET_SERVICE_PARTS,
      payload: mergedList
    });
  };
  const updateEmergencyPartsPricing = async params => {
    const partData = {
      cost: params?.value,
      isEmergencyPart: params?.data?.purchaseType === "EP/Drop",
      manufacturer: params?.data?.dtDmsPartCode,
      partSequence: params?.data?.roPartNum || "",
      oemPartNumber: params?.data?.oemPartNumber,
      payType: service?.payTypeCode,
      qty: params?.data?.quantity,
      serviceType: service?.serviceTypeCode
    };
    const emergencyPartsPricing = await props.updatePartsPricing(partData);
    const emergencyPartPrice = +emergencyPartsPricing?.unitPrice?.toFixed(2);
    if (emergencyPartPrice) {
      params.data.partPriceSource = priceSourceLabels.MANUAL;
      params.data.unitPrice = emergencyPartPrice;
      dispatch({
        type: Actions.SET_SERVICE_PART_PRICE,
        payload: {
          value: emergencyPartPrice,
          rowId: params.data.rowId
        }
      });
      calculatePartsTotal(parts);
      refreshGrid(params);
    }
  };

  const adjustGridColumns = () => {
    setAutoHeight();
    sizeToFit();
  };

  const setAutoHeight = () => {
    if (rowData) {
      const newDomLayout = rowData.length < maxRows ? "autoHeight" : "";
      if (domLayout !== newDomLayout) {
        gridApi.setDomLayout(newDomLayout);
        setDomLayout(newDomLayout);
      }
    }
  };

  const applySortConfig = () => {
    const defaultSortModel = [
      {
        colId: "partName",
        sortIndex: 0,
        sort: "asc"
      }
    ];
    assignColumnState(defaultSortModel);
  };

  const assignColumnState = defaultSortModel => {
    gridColumnApi &&
      gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };

  const sizeToFit = () => {
    gridApi && gridApi.sizeColumnsToFit();
  };

  const handleColumnResized = () => {
    gridApi.resetRowHeights();
  };
  const handleGridSizeChanged = event => {
    const { clientWidth, clientHeight } = event;
    if (clientWidth && clientHeight) {
      sizeToFit();
    }
  };

  const onFirstDataRendered = () => {
    sizeToFit();
  };

  const onGridReady = params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.closeToolPanel();
    applySortConfig();
    sizeToFit();
  };
  // @todo-poc: make sure unique Id given to rowNodeId
  const getRowNodeId = data => {
    return data.rowId ? data.rowId : data.quoteServicePartId;
  };

  const refreshGrid = params => {
    if (params) {
      params.api.refreshCells({ force: true });
    } else {
      gridApi && gridApi.refreshCells({ force: true });
    }
  };
  // callback with prop handler which reads filtered parts after delete action
  const removePart = () => {
    const filteredData = rowData.filter(
      item => item.rowId !== selectedItem.rowId
    );
    setRowData(filteredData);
    setShowPopup(false);
    const totalPartsPrice = calculatePriceForAllParts(
      filteredData,
      "After removePart totalPartsPrice"
    );
    setTotalPrice(totalPartsPrice);
    const partsResult = {
      parts: filteredData,
      deletedItem: selectedItem,
      totalPartsPrice
    };
    dispatch({
      type: Actions.REMOVE_SERVICE_PART,
      payload: selectedItem
    });
    // @todo: update parent state with new parts after delete
    // Note: We pass true to getParts as a 2nd argument when we are removing a part
    props.getParts(partsResult, true);
  };

  const closeDeleteModal = () => {
    setShowPopup(false);
  };

  const openModal = item => {
    setShowPopup(true);
    setSelectedItem(item);
  };

  const removePartPopup = (
    <ConfirmPopup
      title="Alert!"
      message="Are you sure you want to remove this item from the quote?"
      show={showPopup}
      okText="Remove"
      cancelText="Cancel"
      okAction={removePart}
      cancelAction={closeDeleteModal}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );
  const partsGridCls = clsx(
    "ag-grid-container",
    rowData && rowData.length < maxRows
      ? "ops-auto-height ops-grid-small-selector"
      : "ops-parts-grid ops-grid-small-selector",
    //* NOTE: Adding this because for CSR there are many fields and scroll is needed as fields are breaking due to width issue
    appType !== applications.CSR ? "ops-scroll-x-hidden" : "",
    "ag-theme-balham",
    rowData && rowData.length < maxRows && appType === applications.CSR
      ? "ops-overflow-visible"
      : "",
    rowData && rowData.length === 1 ? "ops-overflow-visible" : ""
  );

  //* for CSR parts status render in grid
  const partStatusCellRenderer = params => {
    const partsApprover = params?.value;
    return (
      <Badge
        color={
          partsApprover === null || partsApprover === undefined
            ? "gray"
            : "purple"
        }
        htmlId="partsStatusBadge"
      >
        {partsApprover === null || partsApprover === undefined
          ? "Requested"
          : "Approved"}
      </Badge>
    );
  };

  const partNumberCellRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    return !params.data.oemPartNumber ? "" : params.data.oemPartNumber;
  };
  const quantityCellRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    if (params.data.dmsPending) {
      return (
        <LoadingIndicator
          htmlId="priceLoadingIndicator"
          size="small"
          color="gray"
        />
      );
    }
    const label = qtyFormatter(params);
    // keyId used to be formed with partId. Now, it uses unique rowId coming from API.
    const { rowId, quantityAvailable, isCorePart } = params.data;
    const keyId = "qty_" + rowId;
    const tooltipText = "Quantity Available";
    return (
      <div>
        {transformValueIfUndefined(label)}{" "}
        {toEmptyStringIfUndefined(quantityAvailable) !== "" &&
        !isItCorePart(isCorePart) ? (
          <>
            <span className="qty-separator">&nbsp;/&nbsp;</span>
            <Tooltip htmlId={keyId} tooltipContent={tooltipText}>
              <span className="badge-dms">{quantityAvailable}</span>
            </Tooltip>
          </>
        ) : null}
      </div>
    );
  };
  const qtyFormatter = params => {
    if (!params?.data) {
      return "";
    }
    const { quantity, unitOfMeasure, partType, isCorePart } = params.data;
    let label = "";
    if (partType === "part") {
      label += toEmptyStringIfUndefined(quantity);
    } else if (partType === "fluid" || isCorePart) {
      if (toEmptyStringIfUndefined(quantity) !== "" && quantity !== "NS") {
        label += quantity + " " + toEmptyStringIfUndefined(unitOfMeasure);
      }
    }
    return label;
  };
  // @todo-poc: remove notes badge in description
  const descriptionCellRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    const isM3 = false; // TODO: just a place holder till M3
    // @todo: badges no need to show in description cell
    const {
      partName,
      motorPartName,
      partType,
      notes,
      dmsPending,
      rowId,
      typeOfPurchase,
      oilType
    } = params.data;
    if (dmsPending) {
      return (
        <LoadingIndicator
          htmlId="priceLoadingIndicator"
          size="small"
          color="gray"
        />
      );
    }
    if (motorPartName || partType === "part") {
      let notesTooltip;
      if (!isEmpty(notes) && Array.isArray(notes)) {
        const tipNotes = notes.join(", ");
        notesTooltip = (
          <span className="ops-notes">
            <Tooltip
              htmlId={`id_${rowId}`}
              tooltipContent={tipNotes}
              className="list-tooltip"
            >
              <IconInfoOutline className="info" />
            </Tooltip>
          </span>
        );
      } else {
        notesTooltip = null;
      }
      return (
        <div>
          <div className="ops-part-description">
            <span className="ops-description">
              {getPartDescription(partName, oilType)}
            </span>
            {notesTooltip}
            {
              // TODO: enable this in M3
              isM3 && typeOfPurchase === "EP" ? (
                <Badge className="desc-badge" htmlId="badgeCount" color="count">
                  EP
                </Badge>
              ) : null
            }
            {
              // TODO: enable this in M3
              isM3 && typeOfPurchase === "SO" ? (
                <Badge className="desc-badge" htmlId="badgeCount" color="count">
                  SO
                </Badge>
              ) : null
            }
          </div>
        </div>
      );
    }
    return !partName ? "" : getPartDescription(partName, oilType);
  };
  const availabilityCellRenderer = params => {
    if (!params?.data || params?.data?.approver) {
      return "";
    }

    if (params.data.dmsPending) {
      return (
        <LoadingIndicator
          htmlId="availabilityIndicator"
          size="small"
          color="gray"
        />
      );
    }

    const { quantity, quantityAvailable, isCorePart } = params.data;
    // const quantityOnHand = !isNaN(quantityAvailable) ? quantityAvailable : 0;
    const inStock = isPartInstock(quantity, quantityAvailable);
    const inStockLabel = getPartAvailabilityLabel({ isCorePart, inStock });

    return hasQuantityAvailable(quantityAvailable) &&
      !isItCorePart(isCorePart) ? (
      <div className={inStock ? "stock grid" : "no-stock grid"}>
        {inStockLabel}
      </div>
    ) : (
      <div className="not-available grid">N/A</div>
    );
  };

  const typeRenderer = params => {
    if (!params.data) {
      return "";
    }

    // This is the loading indicator
    if (params.data.dmsPending) {
      return (
        <LoadingIndicator
          htmlId="locationIndicator"
          size="small"
          color="gray"
        />
      );
    }
    const { quantity, quantityAvailable, purchaseType } = params.data;
    // inStock "Out of stock" is false, "In stock" is true
    const inStock = isPartInstock(quantity, quantityAvailable);

    return inStock ? <div className="not-available grid">Stock</div> : ""; // Or EP/dropship when is saved in the API
  };

  const purchaseTypeRenderer = params => {
    if (!params.data) {
      return "";
    }
    const { quantity, quantityAvailable, purchaseType, partType, isCorePart } =
      params.data;
    const inStock = isPartInstock(quantity, quantityAvailable);
    if (partType === "fluid" || isCorePart) {
      return "N/A";
    }
    if (actionType !== "MODIFY_PARTS") {
      if (inStock) {
        return "Stock";
      }
      if (purchaseType === "EP/Drop") return "EP/Drop Ship";
      return purchaseType;
    } else {
      if (inStock) {
        return "Stock";
      } else {
        if (purchaseType) {
          if (purchaseType === "EP/Drop") return "EP/Drop Ship";
          return purchaseType;
        }
        return "Select";
      }
    }
  };
  const locationRenderer = params => {
    if (!params?.data || params?.data?.approver) {
      return "";
    }

    if (params.data.dmsPending) {
      return (
        <LoadingIndicator
          htmlId="locationIndicator"
          size="small"
          color="gray"
        />
      );
    }

    const { location } = params.data;
    return !isEmpty(location) ? (
      <div>{location.name}</div>
    ) : (
      <div className="not-available grid">N/A</div>
    );
  };
  const costRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    if (params.data.dmsPending) {
      return (
        <LoadingIndicator htmlId="costIndicator" size="small" color="gray" />
      );
    }
    const { unitCost = null, unitCostOverride = false } = params.data;
    const formattedCost =
      unitCost !== "" && unitCost !== null && priceValueFormatter(unitCost);
    const divClass = unitCostOverride ? "unit-cost-override" : "";
    const tooltipText =
      "The cost displayed is based on the items material valuation method of average or standard. Pricing based on cost will utilize the 'last in' cost, and will not be utilized when accounting for cost of goods sold for stock items.";
    return formattedCost === 0 || formattedCost ? (
      <Tooltip
        htmlId={`cost_${params?.data?.rowId}`}
        tooltipContent={tooltipText}
      >
        <div className={divClass}>{formattedCost}</div>
      </Tooltip>
    ) : (
      <div className="grid-unit-cost">- -</div>
    );
  };
  // Advance price formatter - input value can be 654321, null, 0, "", "65.34"
  const priceValueFormatter = amount => {
    const options = { style: "currency", currency: "USD" };
    const priceFormat = new Intl.NumberFormat("en-US", options).format(
      amount || 0
    );
    return priceFormat;
  };
  // @todo-poc: if unit price edited, then change partPriceSource as "Manual"
  const priceCellRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    const { dmsPending, unitPrice, dmsPrice, priceSource, partPriceSource } =
      params.data;
    if (dmsPending) {
      return (
        <LoadingIndicator
          htmlId="priceLoadingIndicator"
          size="small"
          color="gray"
        />
      );
    }
    // TODO Verify - read priceSource from catalog API; if null, set MSRP
    const refinedPriceSource = refinePriceSource(priceSource);
    const tempPriceSource = !priceSource
      ? priceSourceLabels.MSRP
      : refinedPriceSource;
    // @note: We use partPriceSource to hold DMS api value and when user modifies unitPrice cell
    const partPriceType = !partPriceSource ? tempPriceSource : partPriceSource;
    let pricePerItem;
    if (partPriceType === priceSourceLabels.MANUAL) {
      pricePerItem = Number(params.value);
    } else {
      pricePerItem = !dmsPrice ? unitPrice : dmsPrice;
    }
    const priceTypeSpan =
      partPriceType === priceSourceLabels.DMS ? (
        <span className="badge-dms">{partPriceType}</span>
      ) : (
        <span className="badge-msrp">{partPriceType}</span>
      );
    return (
      <div className="cx-badge-flex">
        {priceValueFormatter(pricePerItem)}
        {priceTypeSpan}
      </div>
    );
  };

  const totalPriceCellRenderer = params => {
    const { quantity = 0, unitPrice = 0 } = params?.data || {};
    const totalPrice = quantity * unitPrice;

    return priceValueFormatter(totalPrice);
  };

  const checkRemoveButtonDisabled = data => {
    let disabled = false;
    let disabledMessage = "";
    const isCorePartAvailable = gridRef.current.api
      .getRenderedNodes()
      ?.some(
        part =>
          part?.data?.isCorePart &&
          part?.data?.oemPartNumber?.slice(0, -2) === data?.oemPartNumber
      );

    if (data?.isCorePart) {
      // * for core part check whether dirty core part is there
      if (!data?.coreReturnId) {
        const coreReturnAvailable = data?.quoteServicePartId
          ? gridRef.current.api
              .getRenderedNodes()
              ?.some(
                part => part?.data?.coreReturnId === data?.quoteServicePartId
              )
          : false;

        disabled = coreReturnAvailable ? true : false;
        disabledMessage = disabled
          ? "This part cannot be removed without removing core return part."
          : "";
      } else disabled = false;
    } else {
      disabled = isCorePartAvailable;
      disabledMessage = disabled
        ? "This part cannot be removed without removing core part."
        : "";
    }

    if (isCSR && isEmergencyPartsFlagOn) {
      disabled =
        data?.purchaseType !== "STOCK" && data?.purchaseOrderNo ? true : false;
      disabledMessage = disabled
        ? "This part cannot be removed from here as purchase order is added to it"
        : "";
    }
    return { disabled, disabledMessage };
  };

  const removePartCellRenderer = params => {
    if (!isCSR || !params?.data?.approver) {
      return (
        <span title={checkRemoveButtonDisabled(params.data).disabledMessage}>
          <div
            className={`row-remove ${
              checkRemoveButtonDisabled(params.data).disabled ? "blocked" : ""
            }`}
            onClick={() => {
              openModal(params.data);
            }}
          >
            <IconDelete htmlId="iconDelete" className="remove" />
          </div>
        </span>
      );
    }
    return "";
  };

  // @todo: placeholder to callback when dms part record is ready when custom part added
  // eslint-disable-next-line unused-imports/no-unused-vars
  const updateGridRow = record => {
    // ag-grid API to update rowData for edited cell.
    // const rowNode = gridApi && gridApi.getRowNode(record.rowId);
    // if (rowNode) {
    // one way to update specific cells as below
    // rowNode.data["unitPrice"] = record["unitPrice"];
    // rowNode.data["oemPartNumber"] = record.oemPartNumber;
    // rowNode.data["priceSource"] = record.priceSource;
    // rowNode.setData(rowNode.data);
    // rowNode.setData(record);
    // }
  };

  // const onCellEditingStarted = useCallback(() => {
  //   console.log("cellEditingStarted");
  // }, []);

  const onCellEditingStopped = params => {
    refreshGrid(params);
  };

  // Passing frameworkComponents as an object constant to avoid errors
  const customFrameworkComponents = {
    // partsActionMenu: PartsActionMenu,
    customLoadingOverlay: CustomLoadingOverlay,
    customNoRowsOverlay: CustomLoadingOverlay,
    numericEditor: NumericEditor,
    textEditor: TextEditor,
    detailCellRenderer: PurchaseOrderDetailsSectionComponent
  };

  const noRowsOverlayTemplate = "<div>No service parts.</div>";

  const handleCellMouseOver = event => {
    const {
      colDef: { field }
    } = event;
    if (field === "partActions") {
      partActionsGlobals.parts = parts;
    }
  };

  const detailCellRendererParams = useMemo(() => {
    return {
      getPurchaseOrderDetails
    };
  }, []);

  const isRowMaster = params => {
    return params?.purchaseType !== "STOCK" && params?.purchaseOrderNo
      ? true
      : false;
  };

  const updatePoDetailsRow = (detailsData, quoteServicePartId) => {
    const updatedRow = [];
    gridApi.forEachNodeAfterFilterAndSort(rowNode => {
      if (rowNode.data.quoteServicePartId === quoteServicePartId) {
        const updatedData = {
          ...rowNode.data,
          purchaseOrdersDetails: detailsData
        };
        updatedRow.push(updatedData);
      }
    });
    gridApi.applyTransaction({
      update: updatedRow
    });
  };

  const getDetailRowDataForPO = async (poNumber, params) => {
    const quoteServicePartId = params?.data.quoteServicePartId;
    try {
      const detailsData = await props.getPurchaseOrderDetails(poNumber);
      updatePoDetailsRow(detailsData, quoteServicePartId);
      refreshGrid(params);
    } catch (error) {
      updatePoDetailsRow({ error: true }, quoteServicePartId);
      console.error("==> Error fetching details data:", error);
    }
  };

  const onRowGroupOpened = event => {
    const currentRowNode = event.node;

    // If the current row is expanded, set it as the expandedRowId
    if (currentRowNode.expanded) {
      // Collapse all other rows except the current one
      gridApi.forEachNode(node => {
        if (node !== currentRowNode && node.expanded) {
          node.setExpanded(false);
        }
      });

      setExpandedRowId(currentRowNode.data.id);
      const poNumber = event.data?.purchaseOrderNo;
      if (poNumber) {
        getDetailRowDataForPO(poNumber, event);
      }
    } else {
      // If the current row is collapsed, reset the expandedRowId
      setExpandedRowId(null);
    }
  };

  return (
    <>
      <div style={containerStyle}>
        <div className="parts-header">
          <div className="service-parts-amount-container">
            <span>{props.title}</span>
            {rowData ? (
              <Badge
                htmlId="servicePartsAmount"
                className="service-parts-amount-badge"
              >
                {rowData.length}
              </Badge>
            ) : null}
          </div>
          <div>
            {isPartsView ? (
              <span
                className={`unit-cost-time-text-color ${
                  unitCostError ? "unit-cost-error" : "hidden"
                }`}
              >
                {unitCostErrorMessage}
              </span>
            ) : null}

            {!isCSR || (isCSR && userPermissions.canViewPrices) ? (
              <span className="total-price">
                {priceValueFormatter(totalPrice)}
              </span>
            ) : null}
            {!debugMode
              ? ""
              : service.partsPriceOverridden && (
                  <div className="parts-override-label-container">
                    <Badge htmlId="overrideBadge" color="red">
                      {priceValueFormatter(service.totalPartsPriceOverride)}
                    </Badge>
                  </div>
                )}
          </div>
        </div>
        <div id="partsGrid" className={partsGridCls}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={gridColumnDefs}
            defaultColDef={gridOptions.defaultColDef}
            masterDetail={true}
            detailCellRenderer="detailCellRenderer"
            detailCellRendererParams={detailCellRendererParams}
            detailRowHeight={120}
            isRowMaster={isRowMaster}
            frameworkComponents={customFrameworkComponents}
            loadingOverlayComponent={gridOptions.loadingOverlayComponent}
            loadingOverlayComponentParams={
              gridOptions.loadingOverlayComponentParams
            }
            overlayNoRowsTemplate={noRowsOverlayTemplate}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            onCellMouseOver={handleCellMouseOver}
            onColumnResized={handleColumnResized}
            onGridSizeChanged={handleGridSizeChanged}
            getRowNodeId={getRowNodeId}
            sideBar={gridOptions.sideBar}
            enterMovesDownAfterEdit={true}
            enterMovesDown={true}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            // @note: true - use browser default tooltip instead of ag-grid tooltip
            enableBrowserTooltips={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            rowSelection={gridOptions.rowSelection}
            rowDeselection={true}
            columnTypes={gridOptions.columnTypes}
            multiSortKey={gridOptions.multiSortKey}
            domLayout={domLayout}
            onCellValueChanged={onCellValueChanged}
            onCellEditingStopped={onCellEditingStopped}
            onRowGroupOpened={onRowGroupOpened}
          />
        </div>
      </div>
      {userPermissions.hasUpdateQuoteServicePartPermission ||
      (isCSR && userPermissions.canRemoveParts)
        ? removePartPopup
        : ""}
    </>
  );
};

export default SelectedPartsGrid;

SelectedPartsGrid.defaultProps = {
  title: "Parts",
  enableEdit: true,
  parts: [],
  maxRows: 10,
  showPartId: false,
  showActions: false,
  quoteSummary: null,
  service: null,
  getParts: () => {},
  showCorePartReturnModal: () => {},
  showRemovePartModal: () => {}, // @ERP - Action menu modal to remove parts
  onPerformPartAction: () => {}, // @ERP
  updatePartsPricing: () => {}
};

SelectedPartsGrid.propTypes = {
  title: PropTypes.string,
  enableEdit: PropTypes.bool,
  showPartId: PropTypes.bool,
  showActions: PropTypes.bool,
  parts: PropTypes.array,
  maxRows: PropTypes.number,
  getParts: PropTypes.func,
  showCorePartReturnModal: PropTypes.func,
  showRemovePartModal: PropTypes.func,
  onPerformPartAction: PropTypes.func,
  updatePartsPricing: PropTypes.func,
  getPurchaseOrderDetails: PropTypes.func,
  quoteSummary: PropTypes.object,
  service: PropTypes.object
};
