import { useEffect } from "react";

// Only Run Once, on Mount
// `[]` saying - only run on mount, and clean up on unmount
const useComponentDidMount = callback => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, []);
};

export default useComponentDidMount;

// useEffect Example
/*
useEffect(() => {
  console.log("mounted");
  return () => {
    console.log("unmounting...");
  };
}, []);
*/
