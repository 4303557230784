import { toast } from "@cx/ui/Toast";
import { CACHE_NAMES } from "../../../constants/cache-names.constants";
import { payTypeCodes } from "../../../constants/quote-status.constants";
import { getCacheValue, setCacheValue } from "../../../utils/cache.util";
import { PAYER_TYPES, PAYMENT_TYPES } from "../constants/csr.constants";
import isNumber from "lodash/isNumber";
import moment from "moment";

const priorityMap = [
  { type: PAYER_TYPES.S, priority: 3 },
  { type: PAYER_TYPES.W, priority: 1 },
  { type: PAYER_TYPES.C, priority: 0 },
  { type: PAYER_TYPES.I, priority: 2 }
];

/**
 * Retrieves the priority based on the first matching payer type in the provided list.
 * The function checks the types in order of specific priorities defined.
 * If no types match, it defaults to a priority of 3.
 *
 * @param {string[]} payTypes - An array of payer types to be checked.
 * @returns {number} The priority associated with the first matching type, or 3 if no match is found.
 */
const getActivePayersTab = (payTypes = []) => {
  for (const { type, priority } of priorityMap) {
    if (payTypes.includes(type)) {
      return priority;
    }
  }
  return 3; // Default priority if no match is found
};

/**
 * Retrieves a payer from a quote summary based on a specified pay type.
 *
 * @param {Object} quoteSummary - The quote summary object containing payers.
 * @param {string} payType - The pay type to search for within the payers array.
 * @returns {Object|null} The first payer object matching the specified pay type, or null if no match is found.
 */
const getPayerByType = (quoteSummary, payType) => {
  if (!quoteSummary || !Array.isArray(quoteSummary?.payers)) {
    return null;
  }

  return quoteSummary.payers?.find(payer => payer.payType === payType);
};

/**
 * Check if there is any payer without a service contract in the quote summary.
 *
 * @param {Object} quoteSummary - The quote summary object.
 * @param {Array} quoteSummary.payers - Array of payer objects.
 * @returns {boolean} - Returns true if there is at least one payer without an approvalCode in service contract, false otherwise.
 */
const hasPayerWithoutServiceContract = quoteSummary => {
  if (!quoteSummary || !Array.isArray(quoteSummary?.payers)) {
    return false;
  }

  return quoteSummary?.payers
    ?.filter(payer => payer.payType === PAYER_TYPES.S)
    ?.some(payer => !payer?.serviceContract?.approvalCode);
};

/**
 * Check if there is any warranty payer having warranty plan without a approval code in the quote summary.
 *
 * @param {Object} quoteSummary - The quote summary object.
 * @param {Array} quoteSummary.payers - Array of payer objects.
 * @returns {boolean} - Returns true if there is  warranty payer without an approvalCode with warrantyPlan, false otherwise.
 */
const hasWarrantyPayerWithWarrantyPlanWithoutApprovalCode = quoteSummary => {
  if (!quoteSummary || !Array.isArray(quoteSummary?.payers)) {
    return false;
  }

  return quoteSummary?.payers
    ?.filter(payer => payer.payType === PAYER_TYPES.W)
    ?.some(
      payer =>
        !(payer?.warranties.length > 0 && payer?.warranties[0]?.approvalCode)
    );
};

/**
 * Check if there is any payer without a service contract in the quote summary.
 *
 * @param {Object} quoteSummary - The quote summary object.
 * @param {Array} quoteSummary.pickUpDateTime - promised date time.
 * @returns {boolean} - Returns true if promised date time is before checkIndate time, false otherwise.
 */
const isPromisedTimeBeforeCheckInDateTime = quoteSummary => {
  // Parse the given date and time
  const givenDateTime = moment(quoteSummary?.pickUpDateTime);

  // Get the current date and time
  const currentDateTime = moment();

  // Check if the given date and time is before the current date and time
  const isBefore = givenDateTime.isSameOrBefore(currentDateTime);

  return isBefore;
};
/**
 * Checks if a payer has an approval code in the service contract.
 *
 * @param {Object} quoteSummary - The quote summary object containing payers.
 * @param {number} payerId - The ID of the payer to check for an approval code.
 * @returns {(string|boolean)} - The approval code if found, otherwise false.
 */
const payerHasApprovalCode = (quoteSummary, payerId) => {
  if (!quoteSummary || !Array.isArray(quoteSummary.payers)) {
    return false;
  }

  const payer = quoteSummary.payers.find(payer => payer.payerId === payerId);
  return payer?.serviceContract?.approvalCode || false;
};

/**
 * Gets the current number of service contracts based on the provided services and pay type code.
 *
 * @param {Array} services - The list of service objects.
 * @param {string} payTypeCode - The pay type code to filter services by.
 * @returns {number} The number of service contracts matching the pay type code.
 */
const getCurrentNumberOfServiceContracts = (services, payTypeCode) =>
  services?.filter(service => service?.payTypeCode === payTypeCode)?.length ||
  0;

/**
 * Gets the service contract approval code from the provided payers and pay type code.
 *
 * @param {Array} payers - The list of payer objects.
 * @param {string} payTypeCode - The pay type code to find the payer by.
 * @returns {string|undefined} The approval code of the service contract matching the pay type code, or undefined if not found.
 */
const getServiceContractApprovalCode = (payers, payTypeCode) =>
  payers?.find(payer => payer?.payType === payTypeCode)?.serviceContract
    ?.approvalCode;

/**
 * Checks and updates the RO pricing cache.
 *
 * @param {string} quoteId - Quote ID.
 * @param {Array} quoteServices - List of quote services.
 * @param {Object} quoteSummary - Quote summary.
 * @returns {Object} State updates.
 */
const checkROPricingCache = async (quoteId, quoteServices, quoteSummary) => {
  try {
    const roPricing = await getCacheValue(CACHE_NAMES.RO_PRICING, quoteId);
    const deductiblesByQuoteId = await getCacheValue(
      CACHE_NAMES.DEDUCTIBLES,
      quoteId
    );
    const newDeductibles = await getCacheValue(
      CACHE_NAMES.DEDUCTIBLES,
      CACHE_NAMES.DEDUCTIBLES
    );

    const deductibles = deductiblesByQuoteId || newDeductibles;
    const currentNumberOfServices = getCurrentNumberOfServiceContracts(
      quoteServices,
      payTypeCodes.SERVICE_CONTRACT
    );
    const approvalCode = getServiceContractApprovalCode(
      quoteSummary?.payers,
      payTypeCodes.SERVICE_CONTRACT
    );
    const hasServiceContractApproved = !!approvalCode;
    const shouldUpdateCache =
      (!roPricing && hasServiceContractApproved) ||
      (roPricing?.currentNumberOfServices > currentNumberOfServices &&
        hasServiceContractApproved);

    if (shouldUpdateCache) {
      await setCacheValue(CACHE_NAMES.RO_PRICING, quoteId, {
        currentNumberOfServices
      });
    }

    return {
      roPricing,
      deductibles,
      currentNumberOfServices,
      approvalCode,
      hasServiceContractApproved
    };
  } catch (error) {
    console.error("Error checking RO pricing cache:", error);
    throw error;
  }
};

/**
 * Handles the RO pricing banner.
 *
 * @param {string} quoteId - Quote ID.
 * @param {Array} quoteServices - List of quote services.
 * @returns {Object} State updates.
 */
const handleROPricingBanner = async (quoteId, quoteServices) => {
  try {
    const currentNumberOfServices = getCurrentNumberOfServiceContracts(
      quoteServices,
      payTypeCodes.SERVICE_CONTRACT
    );
    await setCacheValue(CACHE_NAMES.RO_PRICING, quoteId, {
      currentNumberOfServices
    });

    return { currentNumberOfServices };
  } catch (error) {
    console.error("Error handling RO pricing banner:", error);
    throw error;
  }
};

/**
 * Updates the RO pricing cache and determines if certain banners should be displayed.
 *
 * @async
 * @function updateROPricingCache
 * @param {string} quoteId - The ID of the quote.
 * @param {Array} quoteServices - The services associated with the quote.
 * @param {Object} quoteSummary - Summary information about the quote.
 * @param {Function} setShowROPricingBanner - Summary information about the quote.
 * @returns {void}
 */
const updateROPricingCache = async (
  quoteId,
  quoteServices,
  quoteSummary,
  setShowROPricingBanner
) => {
  // Destructure the result of the checkROPricingCache function to extract necessary values
  const {
    roPricing,
    currentNumberOfServices,
    deductibles,
    hasServiceContractApproved
  } = await checkROPricingCache(quoteId, quoteServices, quoteSummary);

  // Determine if the RO pricing banner should be shown based on specific conditions
  const shouldShowROPricingBanner =
    roPricing &&
    roPricing?.currentNumberOfServices !== currentNumberOfServices &&
    currentNumberOfServices > 1 &&
    hasServiceContractApproved;

  // Determine if the deductible should be shown based on specific conditions
  const shouldShowDeductible =
    !hasServiceContractApproved && deductibles && currentNumberOfServices > 0;

  // Show the RO pricing banner if the conditions are met
  if (shouldShowROPricingBanner) {
    setShowROPricingBanner(true);
  }

  // Show the deductible and update the cache if the conditions are met
  if (shouldShowDeductible) {
    const { isCreating, numberOfTransfers } = deductibles;
    const transferCount = quoteSummary?.transfers?.length || 0;

    if (isCreating) {
      toast.success("Service contract deductible applied");
      await setCacheValue(CACHE_NAMES.DEDUCTIBLES, CACHE_NAMES.DEDUCTIBLES, {
        ...deductibles,
        isCreating: false
      });
    } else {
      if (isNumber(numberOfTransfers) && transferCount > numberOfTransfers) {
        toast.success("Service contract deductible applied");
        await setCacheValue(CACHE_NAMES.DEDUCTIBLES, quoteId, {
          ...deductibles,
          numberOfTransfers: transferCount
        });
      }
    }
  }
};

const getFormattedTextForPaymentMethod = method => {
  switch (method?.paymentMethod) {
    case PAYMENT_TYPES.CASH:
      return "Cash";
    case PAYMENT_TYPES.CREDIT_CARD:
      return method?.provider;
    case PAYMENT_TYPES.CHECK:
      return "Check";
    case PAYMENT_TYPES.CHARGE:
      return "Charge account";
    case PAYMENT_TYPES.DEALER_POLICY:
      return "Dealer Policy";
  }
};

const getFormattedContentForPaymentMethod = method => {
  switch (method?.paymentMethod) {
    case PAYMENT_TYPES.CASH:
      return `Drawer : ${method?.drawerNumber}`;
    case PAYMENT_TYPES.CREDIT_CARD:
      return method?.transactionCode;
    case PAYMENT_TYPES.CHECK:
      return `Drawer : ${method?.drawerNumber} - #${method?.checkNumber}`;
    case PAYMENT_TYPES.CHARGE:
      return null;
    case PAYMENT_TYPES.DEALER_POLICY:
      return method?.internalAccount;
  }
};

/**
 * Checks if the given closed date has already passed.
 *
 * @param {string|null} closedDateString - The date string to check, in ISO 8601 format.
 *                                         If null or undefined, the function returns false.
 * @returns {boolean} - Returns true if the closed date has passed, false if it has not or if the date is invalid.
 */
const hasClosedDatePassed = closedDateString => {
  if (!closedDateString) {
    // Handle null, undefined, or empty string
    return false; // or true, depending on your desired behavior
  }

  const closedDate = new Date(closedDateString);
  const currentDate = new Date();

  return closedDate < currentDate;
};

export {
  getActivePayersTab,
  getPayerByType,
  hasPayerWithoutServiceContract,
  payerHasApprovalCode,
  getCurrentNumberOfServiceContracts,
  getServiceContractApprovalCode,
  checkROPricingCache,
  handleROPricingBanner,
  updateROPricingCache,
  getFormattedTextForPaymentMethod,
  getFormattedContentForPaymentMethod,
  isPromisedTimeBeforeCheckInDateTime,
  hasClosedDatePassed,
  hasWarrantyPayerWithWarrantyPlanWithoutApprovalCode
};
