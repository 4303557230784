/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./purchase-order-details-section.scss";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import moment from "moment";

const PurchaseOrderDetailsSectionComponent = props => {
  const { data } = props;
  const [isDataLoading, setIsDataLoading] = useState(true);

  function combineNotes(notes) {
    // Combine all notes into a single string separated by commas
    const combinedNote = notes?.map(note => note?.note).join(", ");
    const combinedNoteTypes = notes?.map(note => note?.noteType).join(", ");

    // Return an array with a single object containing noteType and the combined note
    return {
      notesType: combinedNoteTypes,
      notes: combinedNote
    };
  }

  useEffect(() => {
    if (data?.purchaseOrdersDetails) {
      setIsDataLoading(false);
    }
  }, [data]);

  const getPurchaseOrderDetails = data => {
    const poDetailsData = data?.purchaseOrdersDetails;
    return poDetailsData && !poDetailsData.error ? (
      <div className="purchase-order-details-main-container">
        <span className="purchase-order-details-title">Details</span>
        <div className="purchase-order-details-section">
          <div className="purchase-order-details-section-po-number-box">
            <span className="purchase-order-details-section-text">
              PO number
            </span>
            <span className="purchase-order-details-section-data">
              {poDetailsData.purchaseOrderNumber || "---"}
            </span>
          </div>
          <div className="purchase-order-details-section-transDate-box">
            <span className="purchase-order-details-section-text">
              Transaction date
            </span>
            <span className="purchase-order-details-section-data">
              {poDetailsData.transactionDate
                ? moment(poDetailsData.transactionDate).format("MM/DD/YYYY")
                : "---"}
            </span>
          </div>
          <div className="purchase-order-details-section-supplier-box">
            <span className="purchase-order-details-section-text">
              Supplier
            </span>
            <span className="purchase-order-details-section-data">
              {poDetailsData.supplier || "---"}
            </span>
          </div>
          <div className="purchase-order-details-section-supplier-address-box">
            <span className="purchase-order-details-section-text">
              Supplier address
            </span>
            <span className="purchase-order-details-section-data">
              {poDetailsData.supplierAddress || "---"}
            </span>
          </div>
          <div className="purchase-order-details-section-buyer-box">
            <span className="purchase-order-details-section-text">Buyer</span>
            <span className="purchase-order-details-section-data">
              {poDetailsData.buyer || "---"}
            </span>
          </div>
          <div className="purchase-order-details-section-notes-type-box">
            <span className="purchase-order-details-section-text">
              Notes type
            </span>
            <span className="purchase-order-details-section-data">
              {combineNotes(poDetailsData.notes)?.notesType || "---"}
            </span>
          </div>
          <div className="purchase-order-details-section-notes-box">
            <span className="purchase-order-details-section-text">Notes</span>
            <span className="purchase-order-details-section-data">
              {combineNotes(poDetailsData.notes)?.notes || "---"}
            </span>
          </div>
        </div>
      </div>
    ) : (
      <div className="purchase-order-no-details">No Details Available</div>
    );
  };

  return isDataLoading ? (
    <div className="purchase-order-loading">
      <LoadingIndicator
        htmlId="priceLoadingIndicator"
        size="large"
        color="gray"
      />
    </div>
  ) : (
    getPurchaseOrderDetails(data)
  );
};

export default PurchaseOrderDetailsSectionComponent;
